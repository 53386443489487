<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:12px; color:#666; }
.center{text-align: center;}
.role{ max-width:150px; }
.privat td{background-color:#fffaea !important;}
.blocked td{background-color:#eee !important;}
.el-popover{box-shadow:0 0 20px rgba(0,0,0,0.7);}
.tips{margin-top:10px; font-size:12px; color:#981515;}
.el-cascader{width:170px;}
.el-input__inner{width:100%;}
.el-icon-question{margin-left:3px; font-size:16px; cursor:pointer;}
.el-checkbox--mini{padding:4px 8px !important; height:28px !important;}
.el-checkbox--mini .el-checkbox__label{font-size:12px !important; color:#999;}

.edit_dialog{max-height:89%; margin:5vh auto !important; }
.edit_dialog .el-dialog__body{padding:20px;}
.el-dialog .el-row{padding:6px 0;}
.el-dialog .label{line-height: 28px; font-size: 14px; color:#000;}
.el-dialog .btn-add{display:flex; justify-content: center; align-items: center; font-size:22px; color:#aaa;}
.edit_dialog .el-col{height:100%;}
.el-divider{ background-color:#C0C4CC; }
.el-divider__text{padding:0;}
.el-divider__text.is-center{display:flex; justify-content: center; flex-wrap: nowrap; background:transparent;}
.scroll{flex:1 1 auto; height:100%; overflow-y: auto; margin:10px -20px 0 -20px; padding: 0 20px 20px 20px;}
.ask{border:solid 1px #DCDFE6; border-radius:10px; background:#f1f6fa; padding:20px; padding-bottom: 10px;  margin-top:10px; position: relative; box-shadow:3px 3px 15px rgba(0,0,0,0.3); }
.ask .serial{font-size:22px; font-weight: bold; color:#666;}
.edit_dialog .option-pad{display:flex; flex-wrap: wrap; justify-content: flex-start; align-items: stretch;}
.edit_dialog .option{flex:1 0 auto; max-width:30%;  margin:10px 1%; padding:8px 5px; border:solid 1px #DCDFE6; background:#fff; border-radius: 5px; display:flex; align-items: center; justify-content: space-between;}
.edit_dialog .option-key{font-weight: bold; padding-right:2px;}
.edit_dialog .option-input{font-size:14px; color:#409EFF;}
.cursor{cursor:pointer;}
.edit_dialog .option .el-icon-edit-outline{color:#1f901b; cursor:pointer; padding-left:5px; font-size:16px;}
.edit_dialog .option .el-icon-delete{color:red; cursor:pointer;}
.edit_dialog .el-dialog__footer{display:flex; align-items: center;}
.flex-grow{flex:1 1 auto; text-align: left;}


.question{height:89%; margin:5vh auto !important; }
.question .sum-box:nth-of-type(n+2){margin-top:20px;}
.question .sum-box .title{font-size:16px; font-weight: bold; color:#33393a;}
.question .sum-box .option{font-weight: 14px; color:#53595a; padding:3px 0;}
.question .sum-box .chart{min-height:120px; width: 100%;}

.answer_dialog{width:80%; height:89vh; margin:5vh auto !important;}
.answer_dialog .dialog-footer{display: flex; align-items: center;}
.answer_dialog .table-header th:not(.is-center) .cell{font-weight: normal !important; font-size:12px !important;}

.qrcode_dialog{width:400px; height:500px;}
.qrcode_dialog .el-dialog__body{padding:20px;}
</style>
<template>
    <div class="component">
        <!-- 编辑框 -->
        <el-dialog width="700px" title="编辑问卷" custom-class="edit_dialog" v-model="show_edit" :close-on-click-modal="false">
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">问卷标题</span></el-col>
	            <el-col :span="17"><el-input v-model="data_for_edit.title" placeholder="请输入问卷标题" maxlength="50" show-word-limit size="mini"></el-input></el-col>
	            <el-col :span="4">
	                <el-checkbox v-model="data_for_edit.privat" :true-label="1" :false-label="0" border size="mini">私有问卷</el-checkbox>
	            </el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">问卷说明</span></el-col>
	            <el-col :span="21"><el-input v-model="data_for_edit.explain" type="textarea" rows="3" maxlength="200" show-word-limit placeholder="纯文字" size="mini"></el-input></el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="3"><span class="label">开放日期</span></el-col>
	            <el-col :span="9">
	                <el-date-picker style="width:100%" v-model="data_for_edit.open_date" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="mini" value-format="x"></el-date-picker>
	            </el-col>
	            <el-col :span="12"><span class="tips">设定自动开放的日期范围，若未设置，则为开放。</span></el-col>
	        </el-row>
	        <el-row :gutter="10" v-if="gd.me.role>2">

        		<el-col :span="3"><span class="label">可见组织</span></el-col>
	            <el-col :span="8">
	                <el-cascader style="width:100%" v-model="data_for_edit.area_path" :props="cascader_config" size="mini" ref="areas2" @change="this.$refs.areas2.togglePopperVisible()" placeholder="点击选择"></el-cascader>
	            </el-col>
	            <el-col :span="2" class="center"><span class="label">或</span></el-col>
	            <el-col :span="3"><span class="label">可见小区</span></el-col>
	            <el-col :span="8">
	                <el-select v-model="data_for_edit.estate" multiple collapse-tags clearable placeholder="指定小区" size="mini">
					    <el-option v-for="item in gd.estate" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
	            </el-col>
	        </el-row>
	        <div class="scroll" ref="scroll">
	            <el-row v-for="(ask,idx) in data_for_edit.ask" :key="idx">
	                <el-col :span="24">
	                    <div class="ask">
	                        <el-row :gutter="10">
	                        	<el-col :span="1"><div class="serial" v-html="idx+1"></div></el-col>
	                            <el-col :span="13"><el-input v-model="ask.question" placeholder="请输入问题" size="mini"></el-input></el-col>
	                            <el-col :span="7">
	                                <el-radio-group v-model="ask.type" size="mini">
	                                    <el-radio-button label="single">单选</el-radio-button>
	                                    <el-radio-button label="multi">多选</el-radio-button>
	                                    <el-radio-button label="input">问答</el-radio-button>
	                                </el-radio-group>
	                            </el-col>
	                            <el-col :span="3" class="right">
	                                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="click_edit_del_question(idx)">删除</el-button>
	                            </el-col>
	                        </el-row>
	                        <el-divider>
	                            <el-button type="warning" size="small" icon="el-icon-circle-plus" @click="click_edit_add_option(idx)">录入备选项</el-button>
	                            <el-select type="warning" style="margin-left:10px;" placeholder="使用备选项模板" size="small" v-model="option_model_check" @change="click_option_model(idx)">
	                                <el-option v-for="(item,idx0) in option_model" :key="idx0" :label="item.label" :value="item.value"></el-option>
	                            </el-select>
	                        </el-divider>
	                        <div class="option-pad">
	                            <div class="option" v-for="(option,idx1) in ask.option" :key="idx1">

                                    <div class="cursor" @click="click_edit_option(idx,idx1)">
                                        <span class="option-key" v-html="option.key"></span>
                                        <span class="option-value" v-html="option.value"></span>
                                        <i v-if="option.input" class="el-icon-edit option-input"></i>
                                    </div>

	                                <i class="el-icon-edit-outline" @click="click_edit_option(idx,idx1)"></i>

	                                <i class="el-icon-delete" @click="click_edit_del_option(idx,idx1)"></i>

	                            </div>
	                        </div>
	                    </div>
	                </el-col>
	            </el-row>
	        </div> 


	        <template #footer class="dialog-footer">
	        	<el-button type="success" plain icon="el-icon-circle-plus" @click="click_edit_add_question">添加题目</el-button>
	        	<div class="flex-grow"></div>
	            
	            <el-button type="primary" @click="click_edit_done" icon="el-icon-circle-check">确定保存</el-button>
	            <el-button typp="warning" plain @click="show_edit=false" icon="el-icon-circle-close">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 编辑框结束 -->
        <!-- 备选项框 -->
        <el-dialog width="250px" title="编辑备选项" v-model="show_option" :close-on-click-modal="false">
	        <el-row :gutter="10">
	            <el-col :span="4"><span class="label">选项标识</span></el-col>
	            <el-col :span="20">
	                <el-input v-model="option.key" placeholder="例如:A、B、C 或 1、2、3" size="mini"></el-input>
	            </el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="4"><span class="label">选项文字</span></el-col>
	            <el-col :span="20"><el-input v-model="option.value" maxlength="100" show-word-limit placeholder="例如:是、否 或 支持、反对 等" size="mini"></el-input></el-col>
	        </el-row>
	        <el-row :gutter="10">
	            <el-col :span="4"><span class="label">允许输入</span></el-col>
	            <el-col :span="20"><el-checkbox :true-label="1" :false-label="0" v-model="option.input" label="可录输入文字" border size="mini"></el-checkbox></el-col>
	        </el-row>

	        <template #footer class="dialog-footer">
	          
	          <el-button type="primary" @click="click_option_done">确 定</el-button>
	          <el-button @click="show_option = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 备选项框结束 -->
        <!-- 统计框 -->
        <el-dialog title="问卷统计" v-model="show_sum" :close-on-click-modal="false" custom-class="sum_dialog question" :width="650">
        	<div class="scroll">
        		<div class="sum-box" v-for="(item,idx) in sum_for_show" :key="idx">
        			<div class="title" v-html="(idx+1)+'、'+item.question"></div>
        			<el-row v-if="item.type!='input'" :gutter="10">
			            <el-col :span="12">
			            	<div class="option" v-for="(option,idx1) in item.option" :key="idx1" v-html="option.key+'、'+option.value"></div>
			            </el-col>
			            <el-col :span="12">
			                <div class="chart" :id="'chart'+idx"></div>
			            </el-col>
			        </el-row>
			        <el-row v-else :gutter="10">
			            <el-col :span="24" class="option">
			            	问答题
			            </el-col>
			        </el-row>
        		</div>
        	</div>

	        <template #footer class="dialog-footer">
	          <el-button type="primary" @click="show_sum = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 统计框框结束 -->
        <!-- 答卷列表框 -->
        <el-dialog title="答卷列表" v-model="show_answer" :close-on-click-modal="false" custom-class="answer_dialog">
        	<el-table ref="answer_table" size="mini" stripe border :max-height="answer_for_show.table_height" :data="answer_for_show.list" header-row-class-name="table-header" :row-class-name="table_row">
	            <el-table-column label="提交时间" width="160" align="center" fixed>
	            	<template #default="scope"><span v-html="format_datetime(scope.row.created_at)"></span></template>
	            </el-table-column>
	            <el-table-column prop="name" label="提交人" width="120" align="center" fixed></el-table-column>
	            <el-table-column v-for="(ask,idx) in answer_for_show.ask" :key="idx" :label="(idx+1)+'）'+ask.question" width="160" :show-overflow-tooltip="true" class="table-question">
	            	<el-table-column :label="merger_option(ask)" width="160" :show-overflow-tooltip="true" class="table-option">
			            <template #default="scope">
			            	<span v-html="merger_answer(scope.row.answer[idx].value)"></span>
			            </template>
	            	</el-table-column>
	            </el-table-column>
	        </el-table>

	        <template #footer class="dialog-footer">
	        	<el-pagination
			      background
			      small
			      @size-change="answer_table_handleSizeChange"
			      @current-change="answer_table_handlePageChange"
			      :current-page="answer_for_show.page"
			      :page-sizes="[2, 10, 15, 20, 30]"
			      :page-size="answer_for_show.size"
			      layout="total, prev, pager, next, jumper"
			      :total="answer_for_show.total">
			    </el-pagination>
			    <div class="grow">&nbsp;</div>  
	        	<el-button type="primary" @click="show_answer = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 答卷列表框结束 -->
        <!-- 私密问卷二维码框 -->
        <el-dialog title="微信扫描二维码直接访问" v-model="show_qrcode" :close-on-click-modal="false" custom-class="qrcode_dialog">
			<div style="height:100%; display:flex; align-items: center; justify-content: center;">
			    <el-image :src="qrcode_src">
			    	<template #placeholder class="image-slot">
			        	加载中<span class="dot">...</span>
			    	</template>
			    </el-image>
			</div>
	        <template #footer class="dialog-footer">
			    <div class="grow">&nbsp;</div>  
	        	<el-button type="primary" @click="show_qrcode = false">关 闭</el-button>
	        </template>
        </el-dialog>
        <!-- 私密问卷二维码框结束 -->


	    <div class="title-panel">
	        <el-row>
	          <el-col :span="18">
	            <i class="el-icon-document-checked" style="color:#999; font-size:36px; margin-right:5px;"></i>
	            <div class="inline"><div class="label">问卷调查</div><div class="descript">问卷调查的<span class="red">可见组织</span>指该组织和其下级组织。例如所属组织是莲花街道，则莲花街道和其下所有社区用户都可见。</div></div>
	          </el-col>
	          <el-col :span="6" class="btn-col">
	            <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加问卷</el-button>
	            <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
	          </el-col>
	        </el-row>
	    </div>

	    <!-- 筛选栏 -->
	    <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px;">
		    <el-cascader style="width:180px;" v-model="area" :props="cascader_config" clearable size="mini" ref="area1" placeholder="筛选可见区划" @change="this.$refs.area1.togglePopperVisible()"></el-cascader>
		    <div>&nbsp;</div>
		    <el-checkbox v-model="privat" border size="mini">私密问卷</el-checkbox>
			<div>&nbsp;</div>
		    <el-date-picker style="width:270px;" v-model="date" type="daterange" unlink-panels range-separator="至" start-placeholder="开放日期起" end-placeholder="开放日期止" value-format="x" :shortcuts="date_shortcut" size="mini"></el-date-picker>
		    <div>&nbsp;</div>
		    <el-input style="width:180px;" size="mini" v-model="keyword" placeholder="搜索标题" clearable></el-input>    
		    <div style="flex-grow:1">&nbsp;</div>  
		    <el-pagination
		      background
		      small
		      @size-change="handleSizeChange"
		      @current-change="handlePageChange"
		      :current-page="page"
		      :page-sizes="[2, 10, 15, 20, 30]"
		      :page-size="size"
		      layout="total, prev, pager, next, jumper"
		      :total="total">
		    </el-pagination>
	    </el-row>
	    <!-- 筛选栏结束 -->
	    <!-- 主体表格 -->
	    <div class="table-box" id="table-box">
	        <el-table size="mini" stripe border :data="list" :max-height="table_height" header-row-class-name="table-header" :row-class-name="table_row">
	        	<!-- <el-table-column prop="id" label="ID" width="60" align="center"></el-table-column> -->
	            <el-table-column prop="area_name" label="可见组织" width="100" align="center"></el-table-column>
	            <el-table-column prop="title" label="问卷标题" width="200" :show-overflow-tooltip="true"></el-table-column>
	            <el-table-column prop="explain" label="问卷说明" :show-overflow-tooltip="true"></el-table-column>
	            <el-table-column label="开放时间" width="160" align="center">
	                <template #default="scope">{{format_date(scope.row.open_date[0])}}<br>{{format_date(scope.row.open_date[1])}}</template>
	            </el-table-column>
	            <el-table-column prop="click" label="浏览人数" width="100" align="center"></el-table-column>
	            <el-table-column prop="answer" label="参与人数" width="100" align="center"></el-table-column>
	            <el-table-column label="状态" width="100" align="center">
	                <template #default="scope">
	                    <span v-if="scope.row.block == 1">下架中</span>
	                    <span v-else-if="scope.row.valid == -1">手动关闭</span>
	                    <span v-else-if="scope.row.valid == 1">手动开放</span>
	                    <template v-else-if="scope.row.valid == 0">
	                        <span>自动</span>
	                        <span v-if="scope.row.open_date[0] == 0 && scope.row.open_date[1]==0">开放</span>
	                        <span v-else-if="scope.row.open_date[0]>now || scope.row.open_date[1]<now">关闭</span>
	                        <span v-else>开放</span>
	                    </template>
	                </template>
	            </el-table-column>
	            <el-table-column fixed="right" label="操作">
	              <template #default="scope">
	                <div class="line">
	                    <el-button @click="click_block(scope.$index)" type="text" size="small" v-if="scope.row.block==0">下架</el-button>
	                    <el-button @click="click_block(scope.$index)" type="text" size="small" v-else>上架</el-button>
	
	                        <el-popover placement="bottom" :width="400" trigger="click">
	                            <el-card class="box-card">
	                                <el-radio-group v-model="scope.row.valid" @change="click_valid(scope.$index)" size="mini">
	                                    <el-radio-button label="1">手动开放</el-radio-button>
	                                    <el-radio-button label="-1">手动关闭</el-radio-button>
	                                    <el-radio-button label="0">自动开放</el-radio-button>
	                                </el-radio-group>
	                                <el-row :gutter="10" class="tips">
	                                    <el-col :span="4"><b>手动开放</b></el-col>
	                                    <el-col :span="20">设为开放状态，用户可作答</el-col>
	                                </el-row>
	                                <el-row :gutter="10" class="tips">
	                                    <el-col :span="4"><b>手动关闭</b></el-col>
	                                    <el-col :span="20">设为关闭状态，用户不能作答</el-col>
	                                </el-row>
	                                <el-row :gutter="10" class="tips">
	                                    <el-col :span="4"><b>自动开放</b></el-col>
	                                    <el-col :span="20">由问卷的开放日期段决定，若未设定开放日期段，则判定开放，直到变更开放设置</el-col>
	                                </el-row>
	                            </el-card>
	                            <template #reference>
	                            	<el-button type="text" size="small" style="margin:0 10px;">开放设置</el-button>
	                            </template>
	                            
	                        </el-popover>
	    
	                    <el-button @click="click_edit(scope.$index)" type="text" size="small">编辑</el-button>
	                    <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
	                </div>
	                <div class="line">
	                	<el-button @click="click_sum(scope.$index)" type="text" size="small">统计</el-button>
	                	<el-button v-if="scope.row.answer>0" @click="click_answer(scope.$index)" type="text" size="small">查看答卷</el-button>
	                	<el-button v-if="scope.row.privat==1" @click="click_qrcode(scope.$index)" type="text" size="small">二维码</el-button>
	                </div>
	              </template>
	            </el-table-column>
	        </el-table>
	    </div>
	</div>
</template>
<script>
import {inject} from 'vue'
import format_timestamp from "../plugins/utils.js"
import * as echarts from 'echarts'
export default {
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            table_height:800,
		    now:Date.now(),
		    area:'',
		    privat:false,
		    date:'',
		    keyword:'',
		    page:1,
		    total:0,
		    size:10,
		    list: [],
		    show_edit:false,
		    data_for_edit:{ask:[]},
		    show_option:false,
		    option:{},
		    option_model:[
		        {label:'A赞成、B反对', value:[{key:'A',value:'赞成',input:0}, {key:'B',value:'反对',input:0}]},
		        {label:'A非常满意、B比较满意、C不满意、D极不满意', value:[{key:'A',value:'非常满意',input:0}, {key:'B',value:'比较满意',input:0}, {key:'C',value:'不满意',input:0}, {key:'D',value:'极不满意',input:0}]},
		        {label:'A非常好、B较好、C一般、D较差', value:[{key:'A',value:'非常好',input:0}, {key:'B',value:'较好',input:0}, {key:'C',value:'一般',input:0}, {key:'D',value:'较差',input:0}]},
		    ],
		    option_model_check:[],
		    show_sum:false,
		   	sum_for_show:[],
		   	show_answer:false,
		   	answer_for_show:{page:1, size:15, total:0, table_height:0, list:[], ask:[]},
	    	date_shortcut: [{
	            text: '今年一年',
	            value:()=>{
	            	const start = new Date();
	            	start.setMonth(0);
	            	start.setDate(1);
	            	const end = new Date(start.getTime());
	            	end.setMonth(11,31);
	            	return [start, end];
	            }
	        }, {
	            text: '明年一年',
	            value:()=>{
	            	const start = new Date();
	            	start.setYear(start.getFullYear()+1);
	  	        	start.setMonth(0);
	  	        	start.setDate(1);
	  	        	const end = new Date(start.getTime());
	  	        	end.setMonth(11,31);
	  	        	return [start, end];
	            }
	        }],//date_shortcut
		    show_qrcode:false,
		    qrcode_src:'',
		    cascader_config:{
	            expandTrigger:'hover',
	            checkStrictly:true,
	            lazy: true,
	            lazyLoad:(node, resolve)=>{
	                this.axios.post("/api/area/list",{id:node.value || 0}).then(ret=>{
	                    if(!ret.ok){ this.$message.error(ret.msg); return;}
	                    let nodes = [];
	                    ret.data.map(item=>{
	                        nodes.push({value:item.id, label:item.name, leaf:item.hasChildren<1});
	                    });
	                    resolve(nodes);
	                });
	            }
        	},//cascader_config
        }
    },//data end
    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
        this.init();
    },//mounted end
    computed:{

    },
    watch:{
        node:function(){ this.page = 1; this.load_list(); },//industry end
	    date:function(){ this.page = 1; this.load_list(); },//key_unit
	    keyword:function(){ this.page = 1;  this.load_list(); },//keyword end
	    privat:function(){ this.page = 1;  this.load_list(); },//privat end
    },
    methods:{
        format_date:function(stamp){ return format_timestamp(stamp); },//format_date
	    format_datetime:function(stamp){ return format_timestamp(stamp, true); },//format_datetime
	    table_row:function(data){
	    	if(data.row.block== 1){return 'blocked';}
	     	if(data.row.privat == 1){return 'privat';}
	    	return ''
	    },//table-row end
	    init: function(){

	    	this.load_list();    	
	    },//init
	    handleSizeChange:function(val) {
	      this.size = val;
	      this.page = 1;
	      this.load_list();
	    },//handleSizeChange
	    handlePageChange:function(val) {
	      this.page = val;
	      this.load_list();
	    },//handlePageChange

	    load_list:function(){
	    	let params = {page:this.page, size:this.size, area:this.area, date:this.date, key:this.keyword, privat:this.privat};
			this.axios.post("/api/question/list",params).then(ret=>{
				if(!ret.ok){this.$message.error(ret.msg); return;}
				this.list = ret.data.list;
				this.total = ret.data.total;
			});
	    },//load_list
	    click_add:function(){//点击右上角 - 新增问卷
	      this.data_for_edit = {
	        id:0,
	        title:'',
	        explain:'',
	        area_path:[],
	        estate:[],
	        privat:false,
	        open_date:[],
	        ask:[],
	      };
	      this.show_edit = true;
	    },//click_add end
	    click_edit:function(idx){//点击主表格 - 编辑
	      this.show_edit = true;
	      this.axios.post("/api/question/detail",{id:this.list[idx].id}).then(ret=>{
	        if(!ret.ok){ this.$message.error(ret.msg); return;}
	        this.data_for_edit = ret.data;
	      });
	    },//click_edit end
	    
	    click_block:function(idx){//点击主表格 - 上下架
	      this.axios.post("/api/question/block", {id:this.list[idx].id}).then(ret=>{
	        if(!ret.ok){this.$message.error(ret.msg); return;}
	        this.list[idx].block = 1 - this.list[idx].block;
	        this.$message.success('操作成功');
	      });
	    },//click_block end
	    click_valid:function(idx){//点击主表格 - 开放设置
	        this.axios.post("/api/question/valid",{id:this.list[idx].id, valid:this.list[idx].valid}).then(ret=>{
	            if(!ret.ok){this.$message.error(ret.msg); return;}
	            this.$message.success('操作成功');
	        });
	    },//click_valid
	    click_del:function(idx){//点击主表格 - 删除
	      this.$confirm('此操作将永久删除该问卷, 是否继续?', '提示', {
	          confirmButtonText: '确定',
	          cancelButtonText: '取消',
	          type: 'warning'
	        }).then(() => {
	          this.axios.post("/api/question/del", {id:this.list[idx].id}).then(ret=>{
	            if(!ret.ok){this.$message.error(ret.msg); return;}
	            this.list.splice(idx,1);
	            this.$message.success('操作成功');
	          });
	        }).catch((e) => { console.log(e); });
	    },//click_del end
	    click_sum:function(idx){//点击主表格 - 统计
	    	this.sum_for_show = [];
	    	this.axios.post("/api/question/detail", {id:this.list[idx].id}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		this.sum_for_show = ret.data.ask;
	    		this.$nextTick(()=>{ this.render_chart(0)});
	    	});
	    	this.show_sum = true;
	    },//click_sum
	    click_answer:function(idx){//点击主表格 - 查看答卷   	
	    	this.load_answer_for_show_data(idx);
	    	this.show_answer = true;
	    },//click_edit
	    click_qrcode:function(idx){
	    	this.qrcode_src = '';
	    	this.axios.post("/api/question/qrcode", {id:this.list[idx].id}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		this.qrcode_src = ret.data.url;
	    		console.log(ret.data);
	    	});
	    	this.show_qrcode = true;
	    },//click_qrcode
	    //-----------------------编辑框------------------------------------------------------------------------
	    click_edit_add_question:function(){//点击按钮 - 添加问题
	      this.data_for_edit.ask.push({question:'',type:'', option:[]});
	      this.$nextTick(()=>{
	        let scroll = this.$refs.scroll;
	        scroll.scrollTop = scroll.scrollHeight;
	      });
	    },//click_edit_add_question
	    click_edit_del_question:function(idx){//点击删除问题
	      this.data_for_edit.ask.splice(idx,1);
	    },//click_edit_add_question
	    click_edit_add_option:function(idx){//点击按钮 - 添加备选项
	        if(this.data_for_edit.ask[idx].type == ''){this.$message.error('请先选择题型。'); return;}
	        if(this.data_for_edit.ask[idx].type == 'input'){this.$message.error('问答题型 无需设定备选项。'); return;}
	        this.option = {ask_idx:idx, key:'', value:'', input:0};
	        this.show_option = true;
	    },//click_edit_add_option
	    click_option_model:function(idx){//点击选择备选项模板
	        //console.log(idx,this.option_model_check);
	        if(this.data_for_edit.ask[idx].type == ''){this.$message.error('请先选择题型。'); return;}
	        if(this.data_for_edit.ask[idx].type == 'input'){this.$message.error('问答题型 无需设定备选项。'); return;}
	        this.option_model_check.map(item=>{ this.data_for_edit.ask[idx].option.push(item);});
	        this.option_model_check = [];
	    },//click_option_model
	    click_edit_option:function(idx1,idx2){//点击已存在备选项文字 - 编辑
	        this.option = {ask_idx:idx1, option_idx:idx2, key:this.data_for_edit.ask[idx1].option[idx2].key, value:this.data_for_edit.ask[idx1].option[idx2].value, input:this.data_for_edit.ask[idx1].option[idx2].input};
	        console.log(this.option);
	        this.show_option = true;
	    },//click_edit_option
	    click_edit_del_option:function(ask_idx, option_idx){//点击已存在备选项 - 删除
	        this.data_for_edit.ask[ask_idx].option.splice(option_idx,1);
	    },//click_edit_del_option
	    click_option_done:function(){//点击备选项框 - 确定
	        if(this.option.key == ''){this.$message.error("请填写备选项标识"); return;}
	        if(this.option.value == ''){this.$message.error("请填写备选项文字"); return;}
	        if(!this.option.option_idx){//添加的
	            this.data_for_edit.ask[this.option.ask_idx].option.push({key:this.option.key, value:this.option.value, input:this.option.input});
	        }else{//编辑的
	        	this.data_for_edit.ask[this.option.ask_idx].option[this.option.option_idx] = {key:this.option.key, value:this.option.value, input:this.option.input};
	            //this.$set(this.data_for_edit.ask[this.option.ask_idx].option, this.option.option_idx, {key:this.option.key, value:this.option.value, input:this.option.input});
	        }
	        this.show_option = false;
	    },//click_option_done
	    click_edit_done:function(){//点击编辑框 - 确定   	
	    	console.log(this.data_for_edit);
			if(this.data_for_edit.title == ''){ this.$message.error('请输入问卷标题'); return; }
			if(this.data_for_edit.explain == ''){ this.$message.error('请输入问卷说明'); return; }
			if(!this.data_for_edit.privat && this.data_for_edit.area_path.length < 1){ this.$message.error('请选择可见组织'); return; }

			if(this.data_for_edit.ask.length < 1){ this.$message.error('请添加问题'); return; }
			//检查问题
			for(let i=0; i<this.data_for_edit.ask.length; i++){
			let ask = this.data_for_edit.ask[i];
			if(ask.question == ''){ this.$message.error('请输入第 '+(i+1)+' 题题干。'); return; }
			if(ask.type == ''){ this.$message.error('请选择第 '+(i+1)+' 题题型。'); return; }
			if(ask.type != 'input' && ask.option.length < 2){ this.$message.error('请为第 '+(i+1)+' 题至少设定2个备选项。'); return; }
			}

			//console.log(this.data_for_edit);
			this.axios.post("/api/question/edit",this.data_for_edit).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
			this.show_edit = false;
			this.load_list();
			});
	    },//click_edit_done
	    //-----------------------统计框------------------------------------------------------------------------
	    render_chart:function(idx){
	    	//console.log(idx);
	    	let data = [];
	    	if(!this.sum_for_show[idx]){ return; }
	      	this.sum_for_show[idx].option.map(item=>{
	        	data.push({name:item.key, value:item.count});
	     	});    	
	      	if(this.sum_for_show[idx].type == 'single'){
	      		let chart = echarts.init(document.getElementById('chart'+idx));
	      		this.render_bintu(chart,data);
	      	}else if(this.sum_for_show[idx].type == 'multi'){
	      		let chart = echarts.init(document.getElementById('chart'+idx));
	      		this.render_zhuzhuangtu(chart,data);
	      	}
	      	this.render_chart(idx+1);
	    },//render_chart
	    render_bintu:function(chart,data){//饼图
	    	let option = {
	        grid:{
		        bottom:0,
		        left:0,
		        top:0,
		        right:0
	        },
	        title: {
	            text: '',
	            subtext: '',
	            left: 'center'
	        },
	        tooltip: {
	            trigger: 'item'
	        },
	        series: [
	            {
	                name: '选择次数',
	                type: 'pie',
	                radius: ['30%','70%'],
	                data: data,
	                itemStyle: {
		                borderRadius: 10,
		                borderColor: '#fff',
		                borderWidth: 1
		            },
	                emphasis: {
	                    itemStyle: {
	                        shadowBlur: 10,
	                        shadowOffsetX: 0,
	                        shadowColor: 'rgba(0, 0, 0, 0.5)'
	                    }
	                }
	            }
	        ]
	      };
	      chart.setOption(option);
	    },//render_bintu
	    render_zhuzhuangtu:function(chart,data){//柱状图
	    	let x_data = [], y_data=[];
	    	data.map(item=>{x_data.push(item.name); y_data.push(item.value);});
	    	let option = {
		        grid:{
			        bottom:30,
			        left:30,
			        top:20,
			        right:20
		        },
		        xAxis: {

		            data: x_data
		        },
		        yAxis: {
		        	
		        	minInterval:1
		        },
		        series: [{
		            name: '数量：次',
		            type: 'bar',
		            data: y_data
		        }]
	        };
	        chart.setOption(option);
	    },//render_zhuzhuangtu
	    //-----------------------问卷列表框--------------------------------------------------------------------
	    answer_table_handleSizeChange:function(val){
		   	this.answer_for_show.size = val;
		    this.answer_for_show.page = 1;
		    this.load_list();
	    },//answer_table_handleSizeChange
		answer_table_handlePageChange:function(val){
			this.answer_for_show.size = val;
		    this.load_list();
		},//answer_table_handlePageChange
		load_answer_for_show_data:function(idx){
			this.axios.post("/api/question/answer",{page:this.answer_for_show.page, size:this.answer_for_show.size, id:this.list[idx].id, with_ask:1}).then(ret=>{
	    		if(!ret.ok){ this.$message.error(ret.msg); return;}
	    		//table_height 减20 是因为 .el-dialog__body padding:10px
	    		//this.$set(this,'answer_for_show',{page:1, size:15; total:ret.data.total, ask:ret.data.ask, list:ret.data.list, table_height:document.querySelectorAll(".answer_dialog .el-dialog__body")[0].offsetHeight-20});
	    		this.$set(this,'answer_for_show',{total:ret.data.total, ask:ret.data.ask, list:ret.data.list, table_height:document.querySelectorAll(".answer_dialog .el-dialog__body")[0].offsetHeight-20});
	    		//console.log(this.answer_for_show.table_height)
	    		this.$nextTick(()=>{
	    			this.$refs.answer_table.doLayout();
	    		});
	    		//this.answer_for_show.ask = ret.data.ask;
	    		//this.answer_for_show.list = ret.data.list;
	    	});
		},//load_answer_for_show_data
	    merger_option:function(ask){
	    	//console.log(ask);
	    	let option = [];
	    	if(ask.type == 'input'){return "问答题"; }
	    	ask.option.map(item=>{
	    		option.push(item.key+"、"+item.value);
	    	});
	    	return option.join("，");
	    },//merger_option
	    merger_answer:function(value){
	    	//console.log(value);
	    	let answer = [];
	    	value.map(item=>{
	    		let tmp = [];
	    		if(item.key){ tmp.push(item.key); }
	    		if(item.text){ tmp.push(item.text);}
	    		answer.push(tmp.join(":"));
	    	});
	    	return answer.join("、");
	    },//merger_answer
    },//methods end
};
</script>